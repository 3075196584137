.card {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  width: 35rem;
  min-height: 35rem;
  border-radius: 5px;
  margin-bottom: 4.2rem;
  /* margin-right: 3rem; */
  text-align: left;
}

.cardContent {
  text-align: left;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
}

.card img {
  max-width: 35rem;
  cursor: pointer;
}

.card ul {
  list-style: initial;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  margin-bottom: 2rem;
  font-size: 2.4rem;
  padding: 5px;
  cursor: pointer;
}

.title {
  color: teal;
  cursor: pointer;
}
