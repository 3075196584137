.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(rgba(0, 0, 0, 0.9), transparent),
    url("../../images/landing-page.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
}

.text {
  font-size: 4.2rem;
  margin-bottom: 2rem;
}

button {
  padding: 1rem 2rem;
  cursor: pointer;
  border: 2px solid #fff;
  background-color: transparent;
  transition: all 0.5s ease-in-out;
  color: white;
}

button:hover {
  background-color: #05c2c9;
  border-color: #05c2c9;
}
