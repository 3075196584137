.container {
  background: #131c27;
  color: white;
  position: sticky;
  top: 0px;
  min-height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

ul {
  display: flex;
  justify-content: center;
  list-style: none;
  align-items: center;
  flex-wrap: wrap;
}
li {
  margin: 0 5rem;
  cursor: pointer;
}

.active {
  border-bottom: 2px solid #05c2c9;
}
