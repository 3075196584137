* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}
body {
  font-family: "Raleway", sans-serif;
  text-align: center;
}
a {
  color: white;
  text-decoration: none;
}

h1 {
  font-size: 4.8rem;
}
li,
button,
label,
input,
p {
  font-size: 2rem;
}

h3 {
  font-size: 3rem;
  font-weight: normal;
}
h4,
h5 {
  font-size: 2.8rem;
}
