.card {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  width: 80%;
  min-height: 25rem;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 4.2rem;
}

.cardContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.cardContent h4 {
  width: 100%;
  text-align: left;
  border-bottom: 1px solid #ddd;
  margin-bottom: 30px;
  padding: 10px 0;
  color: ;
}

.skills {
  min-width: 100%;
  padding-bottom: 1.5rem;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.skill {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
}

.skill img {
  padding-bottom: 10px;
  max-height: 100px;
}

.skill p {
  font-size: 1.8rem;
}
