.container {
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(rgba(0, 0, 0, 0.8), transparent),
    url("../../images/landing-page.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
}
